'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Breakpoints
 * Simple static class to get the current breakpoint value.
 *
 * Usage:
 * if(Breakpoints.current === 3) {
 *  `Now you kow that this large`
 * }
 */

/**
 * Stores breakpoints values.
 * @type {Object}
 */
var BREAKPOINT = new Map();
BREAKPOINT.set('S', 1);
BREAKPOINT.set('M', 2);
BREAKPOINT.set('L', 3);
BREAKPOINT.set('XL', 4);
BREAKPOINT.set('XXL', 5);

var Breakpoints = function () {
  function Breakpoints() {
    _classCallCheck(this, Breakpoints);
  }

  _createClass(Breakpoints, null, [{
    key: 'current',

    /**
     * Get current breakpoint value
     *
     * @return {number} Number that correspond to the breakpoint.
    */
    get: function get() {
      return BREAKPOINT.get(getComputedStyle(document.querySelector("body"), '::after').content.replace(/'|"/gi, ''));
    }
  }]);

  return Breakpoints;
}();

Breakpoints.BREAKPOINTS = BREAKPOINT;

exports.default = Breakpoints;