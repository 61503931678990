import { default as ElementController, ExecuteControllers }  from 'wtc-controller-element';
import _u from 'wtc-utility-helpers';
import { TimelineMax, TweenMax, Back, Expo } from 'gsap';
import Breakpoints from 'wtc-utility-breakpoint';
import { rmGetState } from './ReduceMotion.js';

const heroVideo = document.querySelector('section.hero video');
let heroVideoCanPlay = false;
let heroAnimDone = false;

class Scene {
  constructor(element, finalFrame) {
    this.element = element;
    this.status = false;
    this.animationComplete = false;
    this.videoEl = document.querySelector('section.hero video');
    this.isabelle = {};
    this.finalFrame = finalFrame;

    this.timeline = new TimelineMax({ paused: true, onComplete: this.onComplete.bind(this)});

  }

  play(finalFrame) {
    // check if scene has played before playing

    if (this.status) {
      return
    }

    let setHeroAnimDone = () => {
      heroAnimDone = true;
    }

    let setIsabelle = () => {
      switch(Breakpoints.current) {

        case 1:
          // this.isabelle.scale = .75;
          // this.isabelle.y = 10;
          // this.isabelle.x = 0;
          this.isabelle.scale = 1;
          this.isabelle.y = 0;
          this.isabelle.x = 0;
          break;
        case 2:
          this.isabelle.scale = .80;
          this.isabelle.y = 5;
          this.isabelle.x = 40;
          break;
        case 3:
          this.isabelle.scale = .65;
          this.isabelle.y = 0;
          this.isabelle.x = 38;
          break;
        default:
          this.isabelle.scale = .8;
          this.isabelle.y = 0
          this.isabelle.x = 55;
      }
    }

    window.addEventListener('resize', () => {
      setIsabelle();
      // console.log(this.animationComplete);

      if (this.animationComplete) {
        // console.log('resize after complete');
        this.timeline.set('.scene4__char', {scale: this.isabelle.scale, xPercent: this.isabelle.x, yPercent: this.isabelle.y});
        this.timeline.set('.scene__copy', {opacity: '0'})

        if (Breakpoints.current >= 2) {
          this.videoEl.currentTime = this.videoEl.duration;
        }
      }
    })

    setIsabelle();

    let desktopAnimation = () => {
      this.timeline.set('.page-home .scene', { opacity: 0 });

      if (rmGetState()) {
        TweenMax.set('.scene4', { y: 0, opacity: 0 });
        TweenMax.set(['.scene4__copy'], { opacity: 0 })
        TweenMax.set('.scene4__char', { scale: this.isabelle.scale, xPercent: this.isabelle.x, yPercent: this.isabelle.y })

        this.timeline
          .to('.scene4', 0.25, { opacity: 1, ease: Back.easeOut })
          .staggerFrom(['.scene4__char', '.scene4__logo', '.scene4__sign'], .5, { opacity: 0, ease: Back.easeOut }, 0.15);
      }
      else {
        this.timeline
          .to('.page-home .scene4', .25, {y: 0, opacity: 1, ease:Back.easeOut})
          .to('.page-home .scene4 .scene4__copy', .25, {y: 0, opacity: 1, ease:Expo.easeOut}, '+=.05')
          .to('.page-home .scene4 .scene4__copy', .15, {opacity: 0, ease:Expo.easeIn}, '+=1.45')
          .to('.scene4__copy', .25, {scale: 0, ease:Expo.easeIn}, '-=.10')
          .to('.scene4__char', .5, {scale: this.isabelle.scale, xPercent: this.isabelle.x, yPercent: this.isabelle.y, ease:Expo.easeOut}, '-=.25')
          .from('.scene4 .scene4__logo', .5, { yPercent: -100, opacity: 0, ease:Back.easeOut}, '-=.25')
          .from('.scene4 .scene4__sign', .75, { scale: 0, ease: Expo.easeOut }, '-=.5');
      }
    }

    let mobileAnimation = () => {
      if (rmGetState()) {

        this.timeline.set('.scene4__char', {opacity: 0});
        this.timeline.set('.page-home .scene', {opacity: 0, y: 0})
          .to('.page-home .scene' + parseInt(this.element+1), .25, {opacity: 1})
          .from('.scene4 .scene4__sign', .75, {opacity: 0}, '-=.25')
          .to('.scene4__char', .25, {opacity: 1}, '-=.5')
          .from('.scene4 .scene4__logo', .5, {opacity: 0});
          
      } else {

        this.timeline.set('.scene4__char', {scale: 0, y: 175})
        this.timeline.set('.page-home .scene', {opacity: 0})
          .to('.page-home .scene' + parseInt(this.element+1), .25, {y: 0, opacity: 1, ease:Back.easeOut})
          .from('.scene4 .scene4__sign', .75, {scale: 0, ease:Expo.easeOut}, '-=.25')
          .to('.scene4__char', .25, {scale: 1, y: 0, ease:Expo.easeOut}, '-=.5')
          .from('.scene4 .scene4__logo', .5, { y: -75, opacity: 0, ease: Back.easeOut });

      }
    }

    if (finalFrame) {
      // play final scene
      Breakpoints.current == 1 ? mobileAnimation() : desktopAnimation()
    } else {
      // play character scenes
      if (rmGetState()) {

        this.timeline.set('.page-home .scene', {opacity: 0, y: 0});
        this.timeline.set('.page-home .scene .scene__copy', {opacity: 0, y: 0})
          .to('.page-home .scene' + parseInt(this.element+1), .25, {y: 0, opacity: 1})
          .to('.page-home .scene' + parseInt(this.element+1)+ ' .scene' + parseInt(this.element+1)+ '__copy', .25, {y: 0, opacity: 1}, '+=.05')
          .to('.page-home .scene' + parseInt(this.element+1)+ ' .scene' + parseInt(this.element+1)+ '__copy', .15, {opacity: 0}, '+=1.45')
          .to('.page-home .scene' + parseInt(this.element+1), .25, {y: 0, opacity: 0}, '+=.10')
          .call(this.rmHandleVideoUpdate, [parseInt(this.element + 1)]);

      } else {
        
        this.timeline.set('.page-home .scene', {opacity: 0});
        this.timeline.set('.page-home .scene .scene__copy', {opacity: 0, y: 25})
          .to('.page-home .scene' + parseInt(this.element+1), .25, {y: 0, opacity: 1, ease:Back.easeOut})
          .to('.page-home .scene' + parseInt(this.element+1)+ ' .scene' + parseInt(this.element+1)+ '__copy', .25, {y: 0, opacity: 1, ease:Expo.easeOut}, '+=.05')
          .to('.page-home .scene' + parseInt(this.element+1)+ ' .scene' + parseInt(this.element+1)+ '__copy', .15, {opacity: 0, ease:Expo.easeIn}, '+=1.45')
          .to('.page-home .scene' + parseInt(this.element+1), .25, {yPercent: 100, opacity: 0, ease:Back.easeIn}, '+=.10');

      }

    }

    this.status = true;
    this.timeline.play()

  }

  onComplete() {
    this.animationComplete = true;
    if (this.finalFrame) {
      // adding this fade for the still image, due to improper browser color management giving an abrupt swap:
      this.videoEl.classList.add('hero-video--ended');
      setTimeout(() => {
        document.querySelector('section.hero video').style.display = 'none';
        heroAnimDone = true;
      }, 1000);
    }
  }

  // handles the manual currentTime update of the video, based on the scene
  rmHandleVideoUpdate(scene) {
    _u.fireCustomEvent('scenechange', {scene})
  }
}

class HomeHeroController extends ElementController {
  constructor(element) {
    super(element);
    this.videoEl = document.querySelector('section.hero video');
    this.scenes = [];

    this.toggleReducedMotion = this.toggleReducedMotion.bind(this);

    document.addEventListener('DOMContentLoaded', ()=> {
      setTimeout(()=> {
        _u.addClass('is-loaded',element);
        this.init()
      }, 500);

      window.addEventListener('reduce-motion', this.toggleReducedMotion);
    });
  }

  init() {
    // create scene playlist
    for (var i = 0; i < 4; i++) {
      this.scenes[i] = new Scene(i, i === 3);
    }

    // play all scenes if viewport is Medium or bigger
    if (Breakpoints.current == 1) {
      setTimeout(() => {
        this.scenes[3].play(true);
      },1000)
    } else {
      if(this.videoEl.paused) {
        let testVideo = this.videoEl;
        let playPromise = testVideo.play();
        if(playPromise !== undefined) {
          playPromise.then(function(){
            // pause, reset, show, and play video from beginning
            // unless it's reduced motion… in that case, we'll handle the currentTime jumps via onComplete callbacks within the scene animations.
            this.videoEl.currentTime = 0;
            if (rmGetState()) {
              this.videoEl.pause();
              this.videoEl.currentTime = 2.8;
            } else {
              this.videoEl.play();
            }
            heroVideoCanPlay = true;
          }.bind(this)).catch(function(error){
            // add a no-autoplay class to html, start intro sequence from the last frame.
            if(error.name === 'NotAllowedError') {
              document.querySelector('html').classList.add('no-autoplay');
              this.videoEl.style.display = 'none';
              this.scenes[3].play(true);
            }
          }.bind(this));
        }
      }

      // for reduced motion, we'll force play the first timeline,
      // then listen to the "scenechange" event, to decide when the "backgrounds" (video timestamps) update.
      if (rmGetState()) {
        this.scenes[0].play();

        window.addEventListener('scenechange', ({ detail }) => {
          let nextSceneNum = detail.scene;
          // console.log('scene change fired, ', `scene #: ${nextSceneNum}`);

          if (nextSceneNum > 0 && nextSceneNum < this.scenes.length) {
            let finalFrameCheck = nextSceneNum === this.scenes.length - 1 ? true : false;
            this.scenes[nextSceneNum].play(finalFrameCheck);
          }

          switch (nextSceneNum) {
            case 1:
              this.videoEl.currentTime = 5;
              break;
            case 2:
              this.videoEl.currentTime = 8;
              break;
            case 3:
              this.videoEl.currentTime = 10.5;
              break;
          }
        });
      } else {
        // for NON-reduced motion, play each timeline based on the video's currentTime:
        this.videoInternal = setInterval(() => {
          if (!this.videoEl.paused) {
            if ((this.videoEl.currentTime >= .65) && (this.videoEl.currentTime <= 3.5)) {
              this.scenes[0].play();
            } else if ((this.videoEl.currentTime >= 3.5) && (this.videoEl.currentTime <= 6.5)) {
              this.scenes[1].play();
            } else if ((this.videoEl.currentTime >= 6.5) && (this.videoEl.currentTime <= 8.75) ){
              this.scenes[2].play();
            } else if (this.videoEl.currentTime >= 9.75) {
              this.scenes[3].play(true);
              clearInterval(this.videoInternal);
            }
          }
        }, 250)
      }
    }
  }

  // Toggle reduced motion for the intro animation timeline.
  // Using a window reload here for simplicity's sake.
  toggleReducedMotion() {
    if (heroAnimDone) {
      window.removeEventListener('reduce-motion', this.toggleReducedMotion);
    } else {
      window.location.reload();
    }
  }
}

ExecuteControllers.registerController(HomeHeroController, 'HomeHeroController');

export { HomeHeroController };
