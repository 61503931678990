'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Viewport = exports.default = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /*
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       Viewports / ViewportManager
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       =======================================
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *Author*          liamegan
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *email*           liam@wethecollective.com
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *Created*         2015-10-07 10:33:20
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *namespace*       com.wtc.utilities
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *Requirements*    jquery   ElementController   wethecollective.utilities.Scroller
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *Description*     These are two classes that are used for inserting and maintinaing viewports. This is useful for running code when the user scrolls a particular viewport into view.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *Edited by*       liamegan
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *Edited*          2017-10-05
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       - *Version*         1.0.22
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     */


var _wtcScroller = require('wtc-scroller');

var _wtcScroller2 = _interopRequireDefault(_wtcScroller);

var _wtcControllerElement = require('wtc-controller-element');

var _wtcControllerElement2 = _interopRequireDefault(_wtcControllerElement);

var _wtcUtilityHelpers = require('wtc-utility-helpers');

var _wtcUtilityHelpers2 = _interopRequireDefault(_wtcUtilityHelpers);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var instance = null;

var ViewportManager = function () {
  function ViewportManager() {
    var _this = this,
        _arguments = arguments;

    _classCallCheck(this, ViewportManager);

    if (!instance) {
      instance = this;
    } else {
      return instance;
    }

    this.VPs = [];

    window.addEventListener('resize', function (e) {
      _this.resize(e);
    });

    _wtcScroller2.default.instance.bind('scroll', function () {
      var args = 1 <= _arguments.length ? [].slice.call(_arguments, 0) : [];
      _this.onScroll.apply(_this, args);
    });

    _wtcScroller2.default.instance.trigger('scroll');
  }

  _createClass(ViewportManager, [{
    key: 'tidy',
    value: function tidy() {
      if (!this.VPs || !this.VPs.length) {
        return;
      }

      var ref = this.VPs;
      for (var i = 0; i < ref.length; i++) {
        var VP = ref[i];
        VP.tidy();
      }
    }
  }, {
    key: 'registerViewport',
    value: function registerViewport(VP) {
      var exists = false;
      exists = this.VPs.indexOf(VP) >= 0;

      if (!exists) {
        this.VPs.push(VP);
      }

      VP.ID = this.VPs.length - 1;

      // This triggers the scroll event after a time after every viewport add
      if (this.scrollTriggerTimeout) clearTimeout(this.scrollTriggerTimeout);
      this.scrollTriggerTimeout = setTimeout(function () {
        _wtcScroller2.default.instance.trigger('scroll');
      }, 500);

      return VP.ID;
    }
  }, {
    key: 'unregisterViewport',
    value: function unregisterViewport(VP) {
      var f = this.VPs.filter(function (el) {
        return el !== VP;
      });

      this.VPs = f;
    }
  }, {
    key: 'getNextVP',
    value: function getNextVP(ID) {
      return this.VPs[ID + 1];
    }
  }, {
    key: 'resize',
    value: function resize(e) {
      var event = document.createEvent('HTMLEvents');
      event.initEvent('scroll', true, false);
      window.dispatchEvent(event);

      this.VPs.forEach(function (item, i) {
        try {
          return item.resize();
        } catch (e) {}
      });
    }
  }, {
    key: 'onScroll',
    value: function onScroll(top, middle, bottom) {
      top = this.winTop;
      var win_height = window.innerHeight;
      bottom = win_height + top;
      middle = top + win_height / 2;
      var body = document.body;
      var html = document.documentElement;
      var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
      var d = height;
      var per = top / (d - win_height);

      this.VPs.forEach(function (item, i) {
        var isOnScreen = item.isOnScreen({
          top: top,
          bottom: bottom
        });

        if (isOnScreen) {
          var topPercent = 0 - (item.top - bottom) / win_height * 100;
          var bottomPercent = (bottom - item.bottom) / win_height * 100;
          var middlePercent = middle / item.bottom * 100;

          try {
            item.runAnimation(topPercent, bottomPercent, middlePercent);
          } catch (e) {
            console.warn(item.element.getAttribute('id'), e.message, e.stack);
          }
        } else {
          try {
            item.reset(top < item.bottom);
          } catch (e) {
            console.warn(e);
          }
        }
      });
    }
  }, {
    key: 'navigateToNext',
    value: function navigateToNext(VP) {
      var nextVP = this.getNextVP(VP.ID);
      var now = window.pageYOffset;
      var point = nextVP.offsetMiddle();
      var duration = (now - point) * 1.5;

      if (duration < 0) {
        duration = duration * -1;
      }

      VP.element.scrollIntoView();
    }
  }, {
    key: 'winTop',
    get: function get() {
      var doc = document.documentElement;
      return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    }
  }], [{
    key: 'instance',
    get: function get() {
      if (!instance) {
        instance = new ViewportManager();
      }
      return instance;
    }
  }]);

  return ViewportManager;
}();

var Viewport = function (_ElementController) {
  _inherits(Viewport, _ElementController);

  function Viewport(element) {
    _classCallCheck(this, Viewport);

    var _this2 = _possibleConstructorReturn(this, (Viewport.__proto__ || Object.getPrototypeOf(Viewport)).call(this, element));

    _this2.element = element;
    _this2.top = null;
    _this2.bottom = null;
    _this2.height = null;
    _this2.ID = null;
    _this2.reverse = _this2.element.getAttribute('data-reverse') && _this2.element.getAttribute('data-reverse') == 'true' ? true : false;

    ViewportManager.instance.registerViewport(_this2);
    return _this2;
  }

  _createClass(Viewport, [{
    key: 'tidy',
    value: function tidy() {
      var exists = this.elementExistsInDOM();
      if (!exists) {
        return ViewportManager.instance.unregisterViewport(this);
      }
    }
  }, {
    key: 'resize',
    value: function resize() {
      var set = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      this.top = this.absoluteTopPosition;
      this.height = this.element.offsetHeight;
      this.bottom = this.top + this.height;
    }
  }, {
    key: 'isOnScreen',
    value: function isOnScreen() {
      var screen = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { top: 0, bottom: 500 };

      var display = this.element.currentStyle ? this.element.currentStyle.display : getComputedStyle(this.element, null).display;
      var pos = this.element.getBoundingClientRect();

      // when top and bottom are 0 the item is inside a wrapper with display none
      if (pos.bottom === 0 && pos.top === 0 || display == 'none') {
        return false;
      }

      this.resize();

      if (this.element.data.debug === true) {
        console.warn(' ');
        console.warn(this.element.getAttribute('id'));
        console.warn('-------------------');
        console.warn("Screen top: " + screen.top);
        console.warn("My top: " + this.top);
        console.warn(this.element.getBoundingClientRect().top);
        console.warn("Screen bottom: " + screen.bottom);
        console.warn("My bottom: " + this.bottom);
        console.warn(screen.top <= this.bottom && screen.bottom >= this.top);
        console.log(this.element);
      }

      if (screen.top <= this.bottom && screen.bottom >= this.top) {
        return true;
      } else {
        return false;
      }
    }
  }, {
    key: 'runAnimation',
    value: function runAnimation(topPercent, bottomPercent, middlePercent) {
      var classString;
      if (topPercent > 0) {
        classString = 'vp-onscreen vp-on-10 vp-on-20 vp-on-30 vp-on-40 vp-on-50 vp-on-60 vp-on-70 vp-on-80 vp-on-90 vp-on-100';
        classString += ' vp-b-10 vp-b-20 vp-b-30 vp-b-40 vp-b-50 vp-b-60 vp-b-70 vp-b-80 vp-b-90 vp-b-100';

        if (this.reverse) {
          _wtcUtilityHelpers2.default.removeClass(classString, this.element);
        }

        _wtcUtilityHelpers2.default.addClass('vp-onscreen', this.element);

        if (topPercent >= 10) {
          _wtcUtilityHelpers2.default.addClass('vp-on-10 vp-onf-10', this.element);
          if (topPercent >= 20) {
            _wtcUtilityHelpers2.default.addClass('vp-on-20 vp-onf-20', this.element);
            if (topPercent >= 30) {
              _wtcUtilityHelpers2.default.addClass('vp-on-30 vp-onf-30', this.element);
              if (topPercent >= 40) {
                _wtcUtilityHelpers2.default.addClass('vp-on-40 vp-onf-40', this.element);
                if (topPercent >= 50) {
                  _wtcUtilityHelpers2.default.addClass('vp-on-50 vp-onf-50', this.element);
                  if (topPercent >= 60) {
                    _wtcUtilityHelpers2.default.addClass('vp-on-60 vp-onf-60', this.element);
                    if (topPercent >= 70) {
                      _wtcUtilityHelpers2.default.addClass('vp-on-70 vp-onf-70', this.element);
                      if (topPercent >= 80) {
                        _wtcUtilityHelpers2.default.addClass('vp-on-80 vp-onf-80', this.element);
                        if (topPercent >= 90) {
                          _wtcUtilityHelpers2.default.addClass('vp-on-90 vp-onf-90', this.element);
                          if (topPercent >= 100) {
                            _wtcUtilityHelpers2.default.addClass('vp-on-100 vp-onf-100', this.element);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (bottomPercent >= 10) {
          _wtcUtilityHelpers2.default.addClass('vp-b-10 vp-bf-10', this.element);
          if (bottomPercent >= 20) {
            _wtcUtilityHelpers2.default.addClass('vp-b-20 vp-bf-20', this.element);
            if (bottomPercent >= 30) {
              _wtcUtilityHelpers2.default.addClass('vp-b-30 vp-bf-30', this.element);
              if (bottomPercent >= 40) {
                _wtcUtilityHelpers2.default.addClass('vp-b-40 vp-bf-40', this.element);
                if (bottomPercent >= 50) {
                  _wtcUtilityHelpers2.default.addClass('vp-b-50 vp-bf-50', this.element);
                  if (bottomPercent >= 60) {
                    _wtcUtilityHelpers2.default.addClass('vp-b-60 vp-bf-60', this.element);
                    if (bottomPercent >= 70) {
                      _wtcUtilityHelpers2.default.addClass('vp-b-70 vp-bf-70', this.element);
                      if (bottomPercent >= 80) {
                        _wtcUtilityHelpers2.default.addClass('vp-b-80 vp-bf-80', this.element);
                        if (bottomPercent >= 90) {
                          _wtcUtilityHelpers2.default.addClass('vp-b-90 vp-bf-90', this.element);
                          if (bottomPercent >= 100) {
                            return _wtcUtilityHelpers2.default.addClass('vp-b-100 vp-bf-100', this.element);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, {
    key: 'reset',
    value: function reset(fillDirection) {}
  }, {
    key: 'scrollY',
    get: function get() {
      var doc = document.documentElement;
      return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    }
  }, {
    key: 'absoluteTopPosition',
    get: function get() {
      return this.element.getBoundingClientRect().top + this.scrollY;
    }
  }], [{
    key: 'middlePoint',
    get: function get() {
      return window.innerHeight / 2 + window.pageYOffset - this.absoluteTopPosition;
    }
  }, {
    key: 'top',
    get: function get() {
      return this.top;
    }
  }, {
    key: 'bottom',
    get: function get() {
      return this.bottom;
    }
  }, {
    key: 'offsetMiddle',
    get: function get() {
      return this.absoluteTopPosition + this.element.height() / 2;
    }
  }]);

  return Viewport;
}(_wtcControllerElement2.default);

// Register


_wtcControllerElement.ExecuteControllers.registerController(Viewport, 'Viewport');

exports.default = ViewportManager;
exports.Viewport = Viewport;