import { ApolloClient, gql } from "../libs/apollo-client";

const client = new ApolloClient({ uri: "https://graph.nintendo.com/" });

const ArticleFields = `#graphql
  id
  locale
  title
  publishDate
  slug
  contentRating {
    code
    label
  }
  contentDescriptors {
    label
    type
  }
  media {
    thumbnailUrl: url(transforms: [{
      resize: { width: "336", type: PAD }
      dpr: "auto"
    }])
    url(transforms: [{
      resize: { width: "756", type: PAD }
      dpr: "auto"
    }])
  }
  body {
    json
    links {
      entries {
        block {
          __typename
          id
          locale
          ... on Media {
            asset {
              publicId
              resourceType
              url(transforms: [{
                resize: { width: "956", type: PAD }
                dpr: "auto"
              }])
            }
          }
          ... on YouTubeVideo {
            videoId
            nocookie
          }
        }
      }
    }
  }
  disclaimers {
    json
  }
`;

const queries = {
  latestArticles: gql`
    query NewsArticlesForACPortal(
      $skip: Int!
      $limit: Int!
      $locale: Locale = en_US
    ) {
      collection: newsArticles(
        locale: $locale
        skip: $skip
        limit: $limit
        where: { tags: { all: ["syndicationAnimalCrossingPortal"] } }
        sort: [publishDate_DESC, priority_DESC]
      ) {
        total
        items {
          ${ArticleFields}
        }
      }
    }
  `,
  articleBySlug: gql`
    query NewsArticleBySlugForACPortal($slug: String!, $locale: Locale = en_US) {
      article: newsArticle(slug: $slug, locale: $locale) {
        ${ArticleFields}
      }
    }
  `
};

const fetchNewsData = async (limit = 20, page = 1, locale) => {
  try {
    const variables = { limit, locale, skip: (page - 1) * limit };
    const { data } = await client.query({ query: queries.latestArticles, variables });
    return [data.collection.items, data.collection.total];
  } catch (err) {
    return [];
  }
};

const fetchNewsFromSlug = async (slug, locale) => {
  try {
    const { data } = await client.query({
      query: queries.articleBySlug,
      variables: { slug, locale }
    });
    return data.article;
  } catch (err) {
    return null;
  }
};

export { fetchNewsData, fetchNewsFromSlug };
