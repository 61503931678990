import { default as ElementController, ExecuteControllers }  from 'wtc-controller-element';
import _u from 'wtc-utility-helpers';
import {Viewport} from 'wtc-controller-viewports';
import { TweenMax, Back } from 'gsap';
import { rmGetState } from './ReduceMotion';

class AmiiboController extends Viewport {
  constructor (element, options = {}) {
    super(element);
    this.isAnimationComplete = false;
    this.tl = new TimelineLite( { paused:true });

    if (!rmGetState()) {
      TweenMax.set('.amiibo-lineup__image', {yPercent: -50, opacity: 0});
      TweenMax.set('.amiibo-lineup__circle', {transform: 'scale(0)'});
    }

  }

  runAnimation(topPercent, bottomPercent, middlePercent) {
    super.runAnimation(topPercent, bottomPercent, middlePercent);
    
    if (!rmGetState()) {
      if (topPercent > 40 && !this.isAnimationComplete) {
        this.isAnimationComplete = true;


        this.tl.staggerTo('.amiibo-lineup__image', 0.25, {yPercent: 0, opacity: 1, ease:Back.easeOut}, .10);
        this.tl.staggerTo('.amiibo-lineup__circle', 0.25, {transform: 'scale(1)', ease:Back.easeOut}, .15, '-=.25');

        this.tl.play();

      }
    }
  }
}

ExecuteControllers.registerController(AmiiboController, 'AmiiboController');

export default AmiiboController;
