import {
  default as ElementController,
  ExecuteControllers
} from "wtc-controller-element";
import _u from "wtc-utility-helpers";
import { Viewport } from "wtc-controller-viewports";
import Preloader from "wtc-utility-preloader";
import { TweenMax, TimelineLite, Power2, Power4, Back } from "gsap";

import CONFIG from "../config";
import { rmGetState } from "./ReduceMotion";

Date.prototype.isLeapYear = function() {
  var year = this.getFullYear();
  if ((year & 3) != 0) return false;
  return year % 100 != 0 || year % 400 == 0;
};

// IE 11 polyfil for string.endsWith
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(searchString, position) {
    var subjectString = this.toString();
    if (
      typeof position !== "number" ||
      !isFinite(position) ||
      Math.floor(position) !== position ||
      position > subjectString.length
    ) {
      position = subjectString.length;
    }
    position -= searchString.length;
    var lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

// Get Day of Year
Date.prototype.getDOY = function() {
  let dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  let mn = this.getMonth();
  let dn = this.getDate();
  let dayOfYear = dayCount[mn] + dn;
  if (mn > 1 && this.isLeapYear()) dayOfYear++;
  return dayOfYear;
};

const LOCALIZATION = {
  "en-US": {
    lang: "en",
    todayIs: "Today is",
    birthdaySuffix: "'s Birthday",
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
  },
  "fr-CA": {
    lang: "fr",
    todayIs: "a sa fête aujourd'hui",
    birthdaySuffix: "",
    monthNames: [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre"
    ]
  },
  "es-LA": {
    lang: "es",
    todayIs: "Hoy es el cumpleaños de",
    birthdaySuffix: "",
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ]
  }
};

class BirthdayController extends Viewport {
  constructor(element, options = {}) {
    super(element);
    this.animationDone = false;
    this.img_url = "assets/img/characters/";
    this.locale = document.querySelector("html").getAttribute("lang");
    this.localeRoot = this.locale !== "en-US" ? (this.localeRoot = "../") : "";
    this.localization = LOCALIZATION[this.locale];
    this.data_url = "assets/data/birthdays.json";
    this.data = {};

    this.parentEl = document.querySelector("section.birthday");
    this.nameEl = document.querySelector(".birthday__name");
    this.dateLabel = document.querySelector(".birthday__label");
    this.imageEl = document.querySelector(".birthday__character-image img");
    this.cheerEl = document.querySelector(".birthday__cheer button");
    this.confettiEl = document.querySelector(".birthday__confetti");

    // parent containers (for load-in animation)
    this.ribbon = document.querySelector(".birthday__ribbon");
    this.cheer = document.querySelector(".birthday__cheer");
    this.copy = document.querySelector(".birthday__copy");
    this.prev = document.querySelector(".birthday__nav .prev");
    this.next = document.querySelector(".birthday__nav .next");

    this.allBirthdays = [];
    this.todaysBirthday = [];
    this.init();
    // this.navBirthday()

    // date stuff
    this.monthNames = this.localization.monthNames;
    this.today = new Date();
    this.dayDifference = 0;
  }

  // convert current Dateobj to DD-MM-YYYY
  convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  fetchData() {
    let basePath = window.location.pathname.replace("/fr", "").replace("/es", "");
    let dataUrl =  basePath + this.data_url;
    let parsed, xhr;

    xhr = new XMLHttpRequest();
    xhr.open("GET", dataUrl, true);
    xhr.callback = this.onLoadJSON.bind(this);
    xhr.onload = function(e) {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          xhr.callback.call(this, xhr);
        } else {
          console.error(xhr.statusText);
        }
      }
    }.bind(this);
    xhr.onerror = function(e) {
      console.error(xhr.statusText);
    };

    xhr.send(null);
  }

  onLoadJSON(request) {
    let parsed = JSON.parse(request.responseText);
    this.format = parsed.format;
    this.allBirthdays = parsed;
    this.loadBirthday(true);
  }

  init() {
    // nav event listeners
    this.prev.addEventListener("click", e => {
      e.preventDefault();
      this.navBirthday("prev");
      nclood.Metrics.trackLink(
        {
          eVars: { 39: "left arrow" },
          events: [53]
        },
        e
      );
    });

    this.next.addEventListener("click", e => {
      e.preventDefault();
      this.navBirthday("next");
      nclood.Metrics.trackLink(
        {
          eVars: { 39: "right arrow" },
          events: [53]
        },
        e
      );
    });

    this.cheerEl.addEventListener("click", e => {
      this.sendCheer(e);
    });

    this.fetchData();
  }

  loadBirthday(firstLoad, navDirection, date) {
    this.todaysBirthday = [];
    let preloader = new Preloader();

    // return matched birthday
    for (let birthday of this.allBirthdays) {
      let birthdayParts = this.convertDate(this.today).split("-");
      let characterBirthdayParts = birthday.birthday.split("-");

      if (
        birthdayParts[0] == characterBirthdayParts[0] &&
        birthdayParts[1] == characterBirthdayParts[1]
      ) {
        this.todaysBirthday.push(birthday);
      }
    }

    if (this.todaysBirthday.length) {
      // load images into preloader
      for (var birthday of this.todaysBirthday) {
        preloader.add(this.localeRoot + this.img_url + birthday.image, "image");
      }

      preloader.load(() => {
        this.displayBirthday(firstLoad);
      });
    } else {
      // console.log(' NO BIRTHDAY FOUND TODAY - SKIPPING');
      this.daySeek(navDirection, firstLoad);
    }

    this.dayDifference = this.dayDifferenceCounter();
    if (this.dayDifference >= 6) {
      // console.log('disabling, ', this.dayDifference);
      this.prev.classList.add("is-disabled");
      this.prev.setAttribute("disabled", true);
    } else {
      this.prev.classList.remove("is-disabled");
      this.prev.removeAttribute("disabled");
    }

    if (this.dayDifference <= 1) {
      this.next.classList.add("is-disabled");
      this.next.setAttribute("disabled", true);
    } else {
      this.next.classList.remove("is-disabled");
      this.next.removeAttribute("disabled");
    }
  }

  daySeek(navDirection, firstLoad) {
    // console.log('DAYSEEKING FROM', this.today);
    let seek = this.today;

    navDirection == "next"
      ? seek.setDate(seek.getDate() + 1)
      : seek.setDate(seek.getDate() - 1);

    // console.log('MOVING TO ', seek)
    this.today = seek;
    this.loadBirthday(firstLoad, null, seek);
  }

  displayBirthday(firstLoad) {
    let now = new Date();
    let dateLabel;
    let birthdaySuffix = this.localization.birthdaySuffix;

    if (this.locale === "en")
      if (this.todaysBirthday[0].name.endsWith("s"))
        birthdaySuffix = "' Birthday";

    this.nameEl.innerHTML = this.todaysBirthday[0].name + birthdaySuffix;

    // figure out if date string is today
    if (
      this.today.getMonth() == now.getMonth() &&
      this.today.getDate() == now.getDate()
    ) {
      dateLabel = this.localization.todayIs;
    } else {

      if (this.localization.lang === "en") {
        dateLabel =
          this.monthNames[this.today.getMonth()] + " " + this.dateOrdinal(this.today.getDate()) + " is";
      } else if (this.localization.lang === "fr") {
        dateLabel =
          `Le ${this.dateOrdinal(this.today.getDate())} ${this.monthNames[this.today.getMonth()]} est le jour de fête de`;
      } else if (this.localization.lang === "es") {
        dateLabel =
          `El ${this.dateOrdinal(this.today.getDate())} ${this.monthNames[this.today.getMonth()]} es el cumpleaños de`;
      }
    }

    this.dateLabel.innerHTML = dateLabel;

    // placeholder image
    // this.imageEl.setAttribute('srcset', this.img_url+'char-birthday-3.png 1x,'+this.img_url+'char-birthday-3_2x.png 2x,');
    // this.imageEl.setAttribute('srcset', this.img_url+this.todaysBirthday[0].image+' 1x,'+this.img_url+this.todaysBirthday[0].image+'_2x.png 2x,');
    // console.log(this.todaysBirthday[0].image);
    this.imageEl.setAttribute(
      "src",
      this.localeRoot + this.img_url + this.todaysBirthday[0].image
    );
    this.imageEl.setAttribute("alt", this.todaysBirthday[0].name);

    if (firstLoad == true) {
      if (!rmGetState()) {
        // console.log('FIRSTLOAD')
        let tl = new TimelineLite({
          paused: true
          // onComplete: this.loadBirthday()
        });
        tl.to(this.ribbon, 0.5, { scale: 1, ease: Back.easeOut }, 0.25).delay(
          0.5
        );
        tl.from(this.copy, 0.35, {
          xPercent: 50,
          opacity: 0,
          ease: Back.easeOut
        });
        tl.from(
          this.cheer,
          0.35,
          { xPercent: -50, opacity: 0, ease: Back.easeOut },
          "-=.35"
        );
        tl.from(
          this.imageEl,
          0.75,
          { scale: 0, ease: Power4.easeOut },
          "-=.20"
        );
        tl.from(
          this.confettiEl,
          0.75,
          { scale: 0, ease: Power4.easeOut },
          "-=.45"
        );
        tl.play();
      } else {
        this.ribbon.style.transform = "scale(1)";
      }
    } else {
      if (rmGetState()) {
        TweenMax.set(this.confettiEl, { opacity: 0 });
      } else {
        TweenMax.set(this.confettiEl, { scale: 0, opacity: 0 });
      }

      TweenMax.to(this.imageEl, 0.45, { x: 0, opacity: 1 }, 0.25);
      TweenMax.to(this.nameEl, 0.5, { scale: 1, opacity: 1 }, 0.25);
      TweenMax.to(this.confettiEl, 0.25, {
        scale: 1,
        opacity: 1,
        ease: Power4.easeOut
      }).delay(0.25);
    }
  }

  dateOrdinal(dom) {
    if (dom == 31 || dom == 21 || dom == 1) {
      if (this.localization.lang === "en") {
        return dom + "st";
      } else {
        return dom;
      }
    }
    else if (dom == 22 || dom == 2) {
      if (this.localization.lang === "en") {
        return dom + "nd";
      } else {
        return dom;
      }
    }
    else if (dom == 23 || dom == 3) {
      if (this.localization.lang === "en") {
        return dom + "rd";
      } else {
        return dom;
      }
    }
    else {
      if (this.localization.lang === "en") {
        return dom + "th";
      } else {
        return dom;
      }
    }
  }

  dayDifferenceCounter() {
    let now = new Date();
    let timeDiff = now.getTime() - this.today.getTime();
    let dayDifference = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return dayDifference;
  }

  navBirthday(direction) {
    this.parentEl.classList.remove("is-active");
    let animationComplete = clickDirection => {
      if (!rmGetState()) {
        TweenMax.set(this.imageEl, { x: 25 });
      }

      this.loadBirthday(null, clickDirection);
    };

    if (direction == "next" && !this.next.classList.contains("is-disabled")) {
      if (rmGetState()) {
        TweenMax.to(this.nameEl, 0.25, { opacity: 0 });
      } else {
        TweenMax.to(this.nameEl, 0.25, { scale: 0.85, opacity: 0 });
      }

      TweenMax.to(this.confettiEl, 0.15, { opacity: 0, ease: Power4.easeOut });

      const onComplete = () => {
        let nextDay = this.today;
        nextDay.setDate(nextDay.getDate() + 1);
        this.today = nextDay;
        animationComplete("next");
      };

      if (rmGetState()) {
        TweenMax.to(this.imageEl, 0.25, { opacity: 0, onComplete });
      } else {
        TweenMax.to(this.imageEl, 0.25, { x: -25, opacity: 0, onComplete });
      }
    }

    if (direction == "prev" && !this.prev.classList.contains("is-disabled")) {
      if (rmGetState()) {
        TweenMax.to(this.nameEl, 0.25, { opacity: 0 });
      } else {
        TweenMax.to(this.nameEl, 0.25, { scale: 0.85, opacity: 0 });
      }

      TweenMax.to(this.confettiEl, 0.15, { opacity: 0, ease: Power4.easeOut });

      const onComplete = () => {
        let prevDay = this.today;
        prevDay.setDate(prevDay.getDate() - 1);
        this.today = prevDay;
        animationComplete("prev");
      };

      if (rmGetState()) {
        TweenMax.to(this.imageEl, 0.25, { opacity: 0, onComplete });
      } else {
        TweenMax.to(this.imageEl, 0.25, { x: -25, opacity: 0, onComplete });
      }
    }
  }

  // cheer stuff
  sendCheer(e) {
    e.preventDefault();

    nclood.Metrics.trackLink({
      eVars: { 39: "birthday cheer" },
      events: [53]
    });

    // data-metric="{ eVars:{39:'birthday cheer'}, events:[53] }"

    let Random2 = (min, max) => {
      return min + Math.floor(Math.random() * (max - min));
    };
    let Random = max => {
      return Math.random() * max;
    };

    let confetti = () => {
      let coloursArray = ["#d95097", "#55c3f1", "#fdd10a", "#8fc31f"];

      let svgNS = "http://www.w3.org/2000/svg";
      let total = 400;
      let body = document.querySelector("body");
      let mySVG = document.createElement("div");
      mySVG.innerHTML =
        '<svg id="mySVG" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"/>';

      body.appendChild(mySVG.firstChild);
      let mySVGEl = body.querySelector("#mySVG");

      let tl = new TimelineLite({
        paused: true,
        onComplete: function() {
          // console.log('timeline complete');
          body.removeChild(document.querySelector("#mySVG"));
          body.classList.remove("js-surprise");
        }
      });

      let animm = elm => {
        let h = window.innerHeight;
        tl.to(elm, Random(4) + 1, { y: h + 30, ease: Power2.easeIn }, 0);
        tl.to(
          elm,
          Random(4) + 1,
          { x: "+=80", yoyo: true, ease: Sine.easeInOut },
          0
        );
        tl.to(
          elm,
          Random(4) + 1,
          {
            scaleX: 0.4,
            rotation: Random(360),
            yoyo: true,
            ease: Sine.easeInOut
          },
          0
        );
        tl.to(
          elm,
          Random(4) + 0.5,
          { opacity: 1, yoyo: true, ease: Sine.easeInOut },
          0
        );
      };

      let w = window.innerWidth;
      let h = window.innerHeight;
      let i = 0;

      while (i < total) {
        let myCircle = document.createElementNS(svgNS, "rect");
        myCircle.setAttributeNS(null, "class", "dot");
        myCircle.setAttributeNS(null, "width", 25);
        myCircle.setAttributeNS(null, "height", 35);
        mySVGEl.appendChild(myCircle);
        let rectW = Random(w);
        TweenMax.set(myCircle, {
          x: rectW,
          y: Random2(150, 200) * -1,
          rotation: Random(180),
          opacity: 1,
          scale: Random(0.5) + 0.5,
          fill: coloursArray[Random2(0, 3)]
        });
        animm(myCircle);
        i++;
      }
      tl.play();
    };

    let balloons = () => {
      let balloonsArray = [
        "icon-balloon-blue",
        "icon-balloon-yellow",
        "icon-balloon-green",
        "icon-balloon-purple"
      ];

      let svgNS = "http://www.w3.org/2000/svg";
      let total = 50;
      let body = document.querySelector("body");
      let mySVG = document.createElement("div");
      mySVG.innerHTML =
        '<svg id="mySVG" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"/>';

      body.appendChild(mySVG.firstChild);
      let mySVGEl = body.querySelector("#mySVG");

      let tl = new TimelineLite({
        paused: true,
        onComplete: function() {
          // console.log('timeline complete');
          body.removeChild(document.querySelector("#mySVG"));
          body.classList.remove("js-surprise");
        }
      });

      let animm = elm => {
        let h = window.innerHeight;
        tl.to(elm, Random(6) + 1, { y: -h - 30, ease: Power2.easeIn }, 0);
        tl.to(
          elm,
          Random(6) + 1,
          { x: "+=200", yoyo: true, ease: Sine.easeInOut },
          0
        );
        tl.to(
          elm,
          Random(4) + 0.5,
          { opacity: 1, yoyo: true, ease: Sine.easeInOut },
          0
        );
      };

      let w = window.innerWidth;
      let h = window.innerHeight;
      let i = 0;

      while (i < total) {
        let myBalloon = document.createElementNS(svgNS, "use");
        myBalloon.setAttributeNS(null, "class", "balloon");
        myBalloon.setAttributeNS(
          "http://www.w3.org/1999/xlink",
          "href",
          "#" + balloonsArray[Random2(0, 4)]
        );
        myBalloon.setAttributeNS(null, "width", 31 * 2);
        myBalloon.setAttributeNS(null, "height", 50 * 2);
        mySVGEl.appendChild(myBalloon);
        let rectW = Random(w);
        TweenMax.set(myBalloon, {
          x: Random(w),
          y: Random2(h, h + 20),
          rotation: 0,
          opacity: 1,
          scale: Random(2) + 0.5
        });
        animm(myBalloon);
        i++;
      }
      tl.play();
    };

    let stars = () => {
      let starsArray = [
        "icon-star-yellow",
        "icon-star-purple",
        "icon-star-green",
        "icon-star-blue",
        "icon-wide-starburst-yellow",
        "icon-wide-starburst-purple",
        "icon-wide-starburst-green",
        "icon-wide-starburst-blue"
      ];

      let svgNS = "http://www.w3.org/2000/svg";
      let total = 60;
      let body = document.querySelector("body");
      let mySVG = document.createElement("div");
      mySVG.innerHTML =
        '<svg id="mySVG" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"/>';

      body.appendChild(mySVG.firstChild);
      let mySVGEl = body.querySelector("#mySVG");

      let tl = new TimelineLite({
        paused: true,
        onComplete: function() {
          // console.log('timeline complete');
          body.removeChild(document.querySelector("#mySVG"));
          body.classList.remove("js-surprise");
        }
      });

      let animm = elm => {
        let h = window.innerHeight;
        tl.to(
          elm,
          Random(1.5) + 0.5,
          {
            opacity: 1,
            ease: Sine.easeInOut
          },
          0
        );

        tl.to(
          elm,
          Math.random() + 0.1,
          {
            opacity: 0,
            yoyo: true,
            repeat: 5,
            delay: Random(5) / 10,
            ease: Sine.easeInOut
          },
          1
        );
        tl.to(
          elm,
          0.3,
          {
            opacity: 1,
            delay: 4,
            ease: Sine.easeOut
          },
          2
        );
      };

      let w = window.innerWidth;
      let h = window.innerHeight;
      let i = 0;

      while (i < total) {
        let star = starsArray[Random2(0, 5)];
        let myStar = document.createElementNS(svgNS, "use");
        myStar.setAttributeNS(null, "class", "star");
        myStar.setAttributeNS(
          "http://www.w3.org/1999/xlink",
          "href",
          "#" + starsArray[Random2(0, 5)]
        );
        myStar.setAttributeNS(null, "width", 30.6);
        myStar.setAttributeNS(null, "height", 30.2);
        mySVGEl.appendChild(myStar);
        let rectW = Random(w);
        TweenMax.set(myStar, {
          x: Random(w),
          y: Random(h),
          opacity: 0,
          scale: Random(2) + 0.5
        });
        animm(myStar);
        i++;
      }
      tl.play();
    };

    let randomNum = Random2(0, 3);
    let randomize = [balloons, confetti, stars];

    randomize[randomNum]();
  }
}

ExecuteControllers.registerController(BirthdayController, "BirthdayController");

export default BirthdayController;
