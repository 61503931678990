'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/*
  Scroller
  =======================================
  Author      liamegan
  email       liam@wethecollective.com
  Created     2014-03-11 15:28:33
  namespace     wtc.utilities
  Requirements  jquery
  Description   This is a singleton class that is used for controlling and reporting on user scrolling.
  Edited by     liamegan
  Edited      2014-06-10 08:34:45
  Version     0.5
*/
var instance = null;

var Scroller = function () {
  function Scroller() {
    var _this = this;

    _classCallCheck(this, Scroller);

    if (!instance) {
      instance = this;
    } else {
      return instance;
    }

    this.events = {};

    window.addEventListener('scroll', function (e) {
      _this.onScroll(e);
    });

    return this;
  }

  _createClass(Scroller, [{
    key: 'getCanScroll',
    value: function getCanScroll() {
      return true;
    }
  }, {
    key: 'onScroll',
    value: function onScroll(e) {
      var bottom = void 0,
          middle = void 0,
          top = void 0,
          wHeight = void 0;

      if (!this.getCanScroll()) {
        return false;
      }

      wHeight = window.innerHeight;
      top = window.pageYOffset;
      bottom = wHeight + top;
      middle = top + wHeight / 2;

      this.trigger('scroll', top, bottom, middle);
      this.triggerPoints(this.oldTop, top, bottom, middle);
      this.oldTop = top;

      return this.oldTop;
    }
  }, {
    key: 'bind',
    value: function bind(topic, handler) {
      var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this;

      var ev = this.events[topic];
      if (!ev) {
        ev = this.events[topic] = [];
      }

      ev.push({
        handler: handler,
        context: context
      });

      return ev;
    }
  }, {
    key: 'bindTriggerPoint',
    value: function bindTriggerPoint(point, handler) {
      var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this;

      var tp = this.events.triggerpoint;
      if (!tp) {
        tp = this.events.triggerpoint = [];
      }

      return tp[point]({
        handler: handler,
        context: context
      });
    }
  }, {
    key: 'bindTriggerElement',
    value: function bindTriggerElement(element, handler) {
      var context = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this;

      var te = this.events.triggerelement;
      if (!te) {
        te = this.events.triggerelement = [];
      }

      te.push({
        element: element,
        handler: handler,
        context: context
      });

      return te;
    }
  }, {
    key: 'trigger',
    value: function trigger() {
      var results = [];
      var topic = arguments[0];
      var args = 2 <= arguments.length ? [].slice.call(arguments, 1) : [];

      if (this.events[topic] !== null) {
        var ref = this.events[topic];
        for (var i = 0; i < ref.length; i++) {
          var event = ref[i];
          results.push(event.handler.apply(event.context, args));
        }

        return results;
      }
    }
  }, {
    key: 'triggerPoints',
    value: function triggerPoints(oldTop, top, bottom, middle) {
      var results = [];

      if (this.events.triggerpoint) {
        var events = this.events.triggerpoint.slice(oldTop, top);
        for (var i = 0; i < events.length; i++) {
          var event = events[i];
          results.push(event.handler.apply(event.context, args));
        }

        return results;
      }
    }
  }, {
    key: 'triggerElements',
    value: function triggerElements(oldTop, top, bottom, middle) {
      var results = [];
      if (this.events.triggerelement) {
        var ref = this.events.triggerelement;

        for (var i = 0; i < ref.length; i++) {
          var event = ref[i];
          var element_top = event.element.getBoundingClientRect().top;
          results.push(element_top >= oldTop && element_top < top ? event.handler.apply(event.context, args) : element_top <= oldTop && element_top > top ? event.handler.apply(event.context, args) : void 0);
        }

        return results;
      }
    }
  }], [{
    key: 'instance',
    get: function get() {
      if (!instance) {
        instance = new Scroller();
      }
      return instance;
    }
  }]);

  return Scroller;
}();

exports.default = Scroller;