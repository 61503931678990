const HTML_EL = document.documentElement;
const LOCAL_KEY = "prefersReducedMotion";
const STATE_CLASS = "is-reduced-motion";
let buttonList = [];
let currentState = localStorage.getItem(LOCAL_KEY);
currentState = currentState === null ? 0 : parseInt(currentState);

const FIRE_EVENT = function () {
  const EVENT = new CustomEvent("reduce-motion", {
    detail: { state: currentState }
  });
  window.dispatchEvent(EVENT);
};

const TOGGLE = function () {
  if (currentState) rmOff();
  else rmOn();
};

const MATCH_MEDIA = function () {
  return window.matchMedia("(prefers-reduced-motion: reduce)").matches;
};

const rmOn = function () {
  currentState = 1;
  localStorage.setItem(LOCAL_KEY, currentState);
  HTML_EL.classList.add(STATE_CLASS);
  FIRE_EVENT();
};

var rmOff = function () {
  currentState = 0;
  localStorage.setItem(LOCAL_KEY, currentState);
  HTML_EL.classList.remove(STATE_CLASS);
  FIRE_EVENT();
};

const rmGetState = function () {
  const MQ = MATCH_MEDIA();

  if (MQ) return 1;

  return currentState;
};

const rmInit = function (buttons = '[data-init="ReduceMotion"]') {
  if (typeof buttons === "object" && buttons.length <= 0) return;

  const ELEMENTS =
    typeof buttons === "string" ? document.querySelectorAll(buttons) : buttons;

  if (!ELEMENTS) return;

  const MQ = MATCH_MEDIA();
  const state = rmGetState();

  for (let button of ELEMENTS) {
    if (MQ) button.setAttribute("hidden", "true");
    else button.addEventListener("click", TOGGLE);
  }

  buttonList.concat([...ELEMENTS]);

  if (state) rmOn();
  else rmOff();
};

const rmDestroy = function () {
  if (buttonList && buttonList.length > 0) {
    for (let btn of buttonList) {
      btn.addEventListener("click", TOGGLE);
    }

    buttonList = [];
  }
};

export { rmGetState, rmOn, rmOff, rmInit, rmDestroy };
