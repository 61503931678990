/**
 * WTC <-> Nintendo Tracking
 * Simple class that abstracts some of the nclood library methods for easy and
 * standardized internal usage.
 * For more information on data-attributes usage, please read:
 * http://media.nintendo.com/share/nclood/stable/docs/#Metrics
 *
 * Namespace: window.wtc.Nintendo
 * Requirements: 0
 * Usage:
 * Initiate the class with all the options :D
 * NS.Nintendo.Tracking.init(trackerProps);
 *
 * Tracking Pages - Omniture.
 * trackPages: true.
 * Just set the option trackPages to true.
 *
 * NOTE: THERE'S NO NEED TO CALL NS.Nintendo.TrackPage() if these options
 * are set. The class will automatica call the necessary tracking functions.
 *
 * Options:
 *
 */

import _u from "wtc-utility-helpers";

let instance = null;

class Tracking {
  constructor(options) {
    if (instance) {
      return instance;
    }

    this.initiated = false;
    this.settings = _u.extend(
      {
        trackerProps: {
          suite: "dev",
          siteName: "",
          pageName: "",
          siteType: "microsite",
          platform: "",
          root: "/"
        },

        pageTrackOpts: {
          productCode: "",
          vanity: undefined,
          productName: undefined,
          contentType: undefined,
          locale: undefined,
          productId: "",
          gameTitle: "",
          genre: "",
          publisher: "",
          eVars: {},
          events: [],
          isHome: false
        },

        language: "EN",
        devMode: false,

        trackPages: true,

        trackAttribute: "data-track"
      },
      options
    );

    if (this.settings.devMode) {
      _u.log("warning", "Tracking instanciated");
    }

    instance = this;

    return this;
  }

  static get instance() {
    return instance;
  }

  static track(opts, event) {
    try {
      if (event && event.target.getAttribute("target") == "_blank") {
        nclood.Metrics.trackExitLink(opts, event);
      } else {
        nclood.Metrics.trackLink(opts);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  static init(options) {
    let tracking = Tracking.instance;

    if (!tracking) {
      tracking = new Tracking(options);
    }

    if (nclood && !instance.initiated) {
      instance.initiated = true;

      if (instance.settings.devMode) {
        _u.log("warning", "Tracking settings are ok.");
        _u.log("info", instance.settings);
      }

      nclood.Metrics.use(instance.settings.trackerProps);

      if (instance.settings.trackPages) {
        if (instance.settings.devMode) {
          _u.log("info", "Tracking Pages");
        }

        instance.trackPage();
      }
    } else {
      if (instance.settings.devMode) {
        _u.log("error", "Could not find nclood library.");
        return null;
      }
    }
  }

  trackPage(properties) {
    if (!properties || typeof propterties != "string") {
      properties = {};
    }

    let opts = _u.deepExtend({}, this.settings.pageTrackOpts, properties);

    try {
      nclood.Metrics.trackPage(opts);
      return true;
    } catch (e) {
      if (this.settings.devMode) {
        _u.log("error", e.message);
      }
      return false;
    }
  }
}

export default Tracking;
