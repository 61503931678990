// Browser Support

// Base
import _u from "wtc-utility-helpers";
import CONFIG from "./App/config";
import { ExecuteControllers } from "wtc-controller-element";
import { rmInit } from "./App/Controllers/ReduceMotion.js";

// Single Controllers
import { Viewport } from "wtc-controller-viewports";
import Gallery from "wtc-gallery-component";
import HomeHeroController from "./App/Controllers/HomeHeroController";
import AmiiboController from "./App/Controllers/AmiiboController";
import BirthdayController from "./App/Controllers/BirthdayController";
import Tracking from "./App/Nintendo/Tracking";
import Utils from "./App/Utils/All";
import renderNews from "./App/news/news-article";
import newsPageInit from "./App/news/news";

// Start tracking.
let trackOpts = Utils.getTrackingOpts();
(trackOpts.devMode =
  CONFIG.CONST.ENV === "DEV" || CONFIG.CONST.ENV === "LOCAL" ? true : false),
  Tracking.init(trackOpts);

// Auto update footer copyright year
document.querySelector(".footer-main .cpdate").html = new Date().getFullYear();

// Instanciate all controllers
ExecuteControllers.instanciateAll();

// reduce motion
rmInit();

// News grid init
const pageSlug = document.body.dataset.pageOmniture;
if (document.documentElement.lang.indexOf("en") > -1) {
  if (pageSlug === "home") renderNews(3, 1, false); 
  else if (pageSlug === "news") newsPageInit();
}