'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Simple Preloader
 * Simple preloader to load images using promises or callbacks.
 * Requirements: none
 * Usage:
 * var preloader = new window.wtc.utilities.Preloader();
 * preloader.add('imagename.jpg', 'image');
 *   OR use an ARRAY
 * preloader.add([{file: 'imagename.jpg', type:'image'}, {file: 'imagename.jpg', type:'image'}]);
 * preloader.load(callback);
 */

var Preloader = function () {
  function Preloader() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref$debug = _ref.debug,
        debug = _ref$debug === undefined ? false : _ref$debug;

    _classCallCheck(this, Preloader);

    var _files = [];
    var _loadCounter = 0;

    this.debug = debug;

    this.filesLoading = [];
    this.callback = function () {};

    this.addFiles = function (file) {
      _files.push(file);
    };
    this.getFiles = function () {
      return _files;
    };

    this.incrementLoadCounter = function () {
      _loadCounter++;
    };
    this.getLoadCounter = function () {
      return _loadCounter;
    };
  }

  /**
   * Add Files to preloader
   *
   * @param  {String or Array} files String filename or Array of File Objects
   */


  _createClass(Preloader, [{
    key: 'add',
    value: function add(files) {
      if (typeof files === 'string' || files instanceof String) {
        var type = arguments[1] || 'image';
        this.addFiles({
          file: files,
          type: type
        });
      } else {
        //appending files array to _files array
        files.map(this.addFiles);
      }
    }
  }, {
    key: 'getImageWithPromise',
    value: function getImageWithPromise(file) {
      var that = this;
      return new Promise(function (resolve, reject) {
        var img = new Image();
        img.onload = function () {
          that.incrementLoadCounter();
          if (that.debug) {
            console.log("i'm loaded: " + file.file);
          }
          resolve(file);
        };
        img.onerror = function () {
          if (that.debug) {
            console.log("file not found: " + file.file);
          }
          resolve(file);
        };
        img.src = file.file;
      });
    }
  }, {
    key: 'loadWithPromises',
    value: function loadWithPromises(callback) {
      var that = this;
      var f = that.getFiles();
      //create an array of images
      var imagePromises = f.map(this.getImageWithPromise.bind(this));
      //wait until all images are loaded.
      Promise.all(imagePromises).then(function (file) {
        callback();
      }).catch(function (err) {
        if (that.debug) {
          console.log('there was an error:');
          console.log('Error Message:');
          console.log(err.message);
          console.log('Error Object:');
          console.log(err);
        }
      });
    }
  }, {
    key: 'loadWithCallbacks',
    value: function loadWithCallbacks(callback) {
      var f = this.getFiles();
      for (var num = 0; num < f.length; num++) {
        if (!f[num].type || f[num].type == 'image') {
          var img = new Image();
          img.addEventListener('load', this.onFilesLoaded.bind(this, callback), false);
          img.addEventListener('error', this.onFilesLoaded.bind(this, callback), false);
          img.src = f[num].file;
          this.filesLoading.push(img);
        }
      }
    }
  }, {
    key: 'onFilesLoaded',
    value: function onFilesLoaded(callback) {
      if (this.debug) {
        if (event.type === "load") {
          console.log("i'm loaded: " + event.path[0].src);
        } else {
          console.log("file not found: " + event.path[0].src);
        }
      }
      this.incrementLoadCounter();
      if (this.getLoadCounter() === this.filesLoading.length && callback) {
        callback(this.getFiles());
      }
    }

    /**
     * Trigger preloader to load assets. Call callback function when complete.
     *
     * @param  {function} callback - Callback function
     */

  }, {
    key: 'load',
    value: function load(callback) {
      if (window.Promise) {
        this.loadWithPromises(callback);
      } else {
        this.loadWithCallbacks(callback);
      }
    }
  }]);

  return Preloader;
}();

exports.default = Preloader;