import Modal from "wtc-modal-view";
import { fetchNewsData, fetchNewsFromSlug } from "./news-data";
import newsArticleRichText from "./news-article-rich-text";
import Utils from "../Utils/All";
import CONFIG from "../config";

const newsWrapper = document.querySelector("[data-news]");
let newsLoader, newsError, seeAllButton, modalBackground, DOMBody, hiddenClass, backButton, altPrefix, displayText;

if (newsWrapper) {
  newsLoader = newsWrapper.parentNode.querySelector(".loader");
  newsError = newsWrapper.parentNode.querySelector(".news-error");
  seeAllButton = newsWrapper.parentNode.querySelector("a.button");
  modalBackground = document.querySelector(".news-modal-bg");
  DOMBody = document.body;
  hiddenClass = "node-hidden";
  backButton =
    document.querySelector(".button-js-clone .button") ||
    document.createElement("button");
    
  // The prefix for a news article's hidden alt text; i.e. "News article: " followed by the title.
  altPrefix = newsWrapper.dataset.hiddenText || "";
  // The word for the article CTA; i.e. "Read"
  displayText = newsWrapper.dataset.displayText || "";
}

const trackPrefix = document.body.dataset.pageOmniture;

const backgrounds = ["squares-purple", "triangles-green", "squares-blue"];
const numColors = backgrounds.length;

let page = 1;
let initialized = false;
let lastColorIndex = 0;

/**
 * Calls the news endpoint, renders a news grid, and initializes modals.
 */
const renderNews = async (
  limit = 1,
  page = 1,
  useModal = true
) => {
  if (!newsWrapper) return;

  try {
    const [articles, total] = await fetchNewsData(limit, page);

    articles.forEach((article, i) => {
      const color = backgrounds[(i + lastColorIndex) % numColors];
      createArticleMarkup(article, color, useModal);
    });

    lastColorIndex = articles.length;

    if (!initialized) {
      setTimeout(() => {
        newsWrapper.dataset.news = "loaded";
        newsLoader.classList.remove("loader--loading");
      }, 200);

      if (useModal) {
        checkNewsModalHash();
      }

      initialized = true;
    } else {
      newsWrapper.dataset.loadMore = true;
      setTimeout(() => {
        newsWrapper.removeAttribute("data-load-more");
      }, 400);
    }

    if (useModal) {
      newsModalInit();
    }

    return total;
  } catch (err) {
    console.warn(err);
    newsWrapper.dataset.news = "error";

    newsLoader.classList.remove("loader--loading");
    newsError.classList.add("news-error--shown");

    if (seeAllButton) newsWrapper.parentNode.removeChild(seeAllButton);

    return false;
  }
};

/**
 * Creates a single news article component.
 * @param {Object} article Object of fields returned by the Graph API
 * @param {string} color Color of the text card border
 * @returns {HTMLElement} the list item.
 */
const createArticleMarkup = (
  { media, publishDate, title, body, disclaimers, slug, contentRating, contentDescriptors },
  color,
  useModal = true
) => {
  const dateObj = new Date(publishDate);
  let formattedDate = dateObj
    .toDateString()
    .replace(/^\D{3}\s/, "")
    .replace(/(\s\d{4}$)/, "");

  formattedDate = `${formattedDate}, ${dateObj.getFullYear()}`;

  // create elements:
  const listItem = document.createElement("li"),
    article = document.createElement("article"),
    articleWrap = document.createElement("a"),
    articleContentSRHidden = document.createElement("div"),
    articleMore = document.createElement("span"),
    articleTitle = document.createElement("span"),
    articleDate = document.createElement("span"),
    articleEsrbContainer = document.createElement("div"),
    articleEsrb = document.createElement("esrb-rating"),
    articleDivider = document.createElement("hr"),
    articleThumb = new Image();

  // add attributes:
  listItem.className = "column-flex column-flex-4 news-item";
  articleWrap.setAttribute("aria-label", `${altPrefix}${title}`);
  articleWrap.dataset.background = color;
  articleContentSRHidden.className = "news-article";
  articleContentSRHidden.setAttribute("aria-hidden", true);
  articleTitle.className = "news-article__title";
  articleDate.className = "news-article__date text-teal";
  articleThumb.className = "news-article__thumb";
  articleMore.classList.add("news-article__more");
  articleEsrbContainer.className = "esrb_container";

  // populate content
  articleWrap.href = `news/#!/${slug}`;
  articleMore.textContent = displayText;
  articleTitle.textContent = title;
  articleDate.textContent = formattedDate;

  // Set up the thumbnail
  articleThumb.src = media && media.thumbnailUrl
  articleThumb.alt = "";

  // add the grid markup
  articleContentSRHidden.appendChild(articleDate);
  articleContentSRHidden.appendChild(articleTitle);
  articleContentSRHidden.appendChild(articleThumb);
  articleContentSRHidden.appendChild(articleMore);
  articleWrap.appendChild(articleContentSRHidden);
  article.appendChild(articleWrap);
  listItem.appendChild(article);

  if (useModal) {
    // create the elements
    const articleModalTitle = document.createElement("h2"),
      articleBody = document.createElement("div"),
      articleLegal = document.createElement("div"),
      articleThumbWrapper = document.createElement("div"),
      articleMainImage = new Image(),
      articleContent = document.createElement("div"),
      articleContentInner = document.createElement("div"),
      articleEsrb = document.createElement("esrb-rating"),
      logoLink = document.createElement("a"),
      logo = new Image(),
      logoPath = `../assets/img/news/`;

    // add attributes
    logoLink.href = `${CONFIG.CONST.LANGUAGE === "en" ? "../" : "../" + CONFIG.CONST.LANGUAGE}`
    logoLink.className = "news-article__logo";
    articleModalTitle.className = "news-article__title";
    articleBody.className = "news-article__body";
    articleLegal.className = "news-article__legal txt-legal";
    articleThumbWrapper.className = "news-article__thumb-wrapper fancy-border";
    articleMainImage.className = "news-article__thumb";
    articleWrap.dataset.newsModalTrigger = "true";
    articleWrap.href = `#!/${slug}`;
    articleContent.className = "news-article__modal-content";
    articleContentInner.className = "news-article__modal-content-inner";

    //populate content:
    logo.src = `${logoPath}news-logo.png`;
    logo.srcset = `${logoPath}news-logo.png 1x, ${logoPath}news-logo-2x.png 2x`;
    logo.alt = "Welcome to Animal Crossing"
    articleModalTitle.textContent = title;
    articleMainImage.src = media && media.url
    articleMainImage.alt = "";
    if (body)
      articleBody.innerHTML = newsArticleRichText(body);
    if (disclaimers)
      articleLegal.innerHTML = newsArticleRichText(disclaimers);
    if (!disclaimers)
      articleContentInner.appendChild(articleDivider);

    if (contentDescriptors.length) {
      let descriptors = contentDescriptors.reduce((r, a) => {
        r[a.type] = [...r[a.type] || [], a];
        return r;
       }, {});

      //if there are CONTENT_DESCRIPTORS, map through to create string to pass to esrb-rating "descriptors" attribute 
      if (descriptors.CONTENT_DESCRIPTOR) {
        articleEsrb.setAttribute("descriptors", descriptors.CONTENT_DESCRIPTOR.map(descriptor => descriptor.label).join(", "));
      }

      //if there are CONTENT_DESCRIPTORS, map through to create string to pass to esrb-rating "descriptors" attribute
      if (descriptors.INTERACTIVE_ELEMENT) {
        articleEsrb.setAttribute("interactive-elements", descriptors.INTERACTIVE_ELEMENT.map(descriptor => descriptor.label).join(", "));
      }
    }

    if (contentRating) {
      articleEsrb.setAttribute("rating", contentRating.code)
    }

    if (contentRating) { 
      articleEsrb.alt = contentRating.label
    }

    // add the modal markup
    logoLink.appendChild(logo);
    articleContentInner.appendChild(backButton.cloneNode(true));
    articleContentInner.appendChild(articleModalTitle);
    articleContentInner.appendChild(articleDate.cloneNode(true));
    articleThumbWrapper.appendChild(articleMainImage);
    articleContentInner.appendChild(articleThumbWrapper);
    articleContentInner.appendChild(articleBody.cloneNode(true));
    if (disclaimers) articleContentInner.appendChild(articleLegal.cloneNode(true));
    if (contentRating) {
      articleContentInner.appendChild(articleEsrbContainer);
      articleEsrbContainer.appendChild(articleEsrb);
    }
    articleContentInner.appendChild(backButton.cloneNode(true));
    articleContent.appendChild(logoLink);
    articleContent.appendChild(articleContentInner);
    article.appendChild(articleContent);
  }

  newsWrapper.appendChild(listItem);

  // article click tracking
  articleWrap.addEventListener("click", event => {
    nclood.Metrics.trackLink(
      {
        eVars: {
          37: `${trackPrefix}: ${trackPrefix === "news" ? "" : "news"}: ${slug}`
        },
        events: [53]
      },
      event
    );
  });

  return listItem;
};

/**
 * Moves the modal out of the direct root of the document (body),
 * and into the <main> element. This helps with styling.
 */
const adjustModalCharacteristics = () => {
  if (!Modal.instance.appended) {
    // move it into <main>
    document
      .querySelector("main")
      .appendChild(document.querySelector(".modal"));

    // Adjust the close button's markup
    const closeBtn = Modal.instance.modalClose;
    const closeText = document.createElement("span");
    closeBtn.removeChild(closeBtn.querySelector("span"));
    closeText.textContent = "Back";
    closeBtn.appendChild(closeText);
  }
};

/**
 * Handles the opening of the modals, relocation of the modals,
 * and adding the hide classes to various content bits.
 * @param {String|HTMLElement} modalContent content to inject into the modal
 * @param {HTMLElement} trigger the element which will receive focus on close.
 */
const onModalOpen = (modalContent, trigger = null) => {
  adjustModalCharacteristics();
  Modal.open(modalContent, "modal--news", trigger);

  // apply appropriate modal background
  Modal.instance.bgType = trigger.dataset.background;
  modalBackground.classList.add(
    `news-modal-bg--${Modal.instance.bgType}`,
    "news-modal-bg--shown"
  );

  if (window.scrollY < 100) {
    Modal.instance.modal.style.setProperty("--top-offset", "138px");
  }

  // link up our custom close buttons
  const backButtons = Modal.instance.modalContent.querySelectorAll(
    ".button--back"
  );
  if (backButtons && backButtons.length > 0) {
    for (const btn of backButtons) {
      btn.addEventListener("click", Modal.close);
    }
  }

  DOMBody.classList.add("news-modal-open");
  newsWrapper.classList.add(hiddenClass);
};

/**
 * Handles the closing of the modals. Removes the node-hidden classes,
 * and removes any hash from the url.
 */
const onModalClose = () => {
  // remove listeners on our custom close buttons
  const backButtons = Modal.instance.modalContent.querySelectorAll(
    ".button--back"
  );
  if (backButtons && backButtons.length > 0) {
    for (const btn of backButtons) {
      btn.removeEventListener("click", Modal.close);
    }
  }

  modalBackground.classList.remove(
    `news-modal-bg--${Modal.instance.bgType}`,
    "news-modal-bg--shown"
  );

  Modal.instance.modal.style = "";

  setTimeout(() => DOMBody.classList.remove("news-modal-open"), 200);
  newsWrapper.classList.remove(hiddenClass);

  if (window.location.hash) {
    history.pushState({}, "", window.location.href.split("#")[0]);

    // if there exists a temporary grid item (see checkModalHash function for WHY that might be), remove it:
    const tempItem = document.getElementById("temporary-news-item");
    if (tempItem) {
      tempItem.remove();
      window.scroll(0, 0);
    }
  }

  // track the modal close:
  nclood.Metrics.trackPage(
    Object.assign(
      Utils.getTrackingOpts().pageTrackOpts,
      {
        isHome: false,
        events: [53],
        eVars: { 39: "news: back button" }
      }
    )
  );
};

/**
 * Sets up the behaviour for the news detail modal,
 * including some minimal history events.
 */
const newsModalInit = () => {
  if (!newsWrapper) return;

  const modalTriggers = document.querySelectorAll("[data-news-modal-trigger]");

  if (modalTriggers && modalTriggers.length > 0) {
    for (const btn of modalTriggers) {
      const modalContentWrapper = btn.parentNode.querySelector(
        ".news-article__modal-content"
      );
      if (modalContentWrapper) {
        btn.addEventListener("click", () => {
          onModalOpen(modalContentWrapper.innerHTML, btn);
        });
      }
    }
  }

  Modal.onClose = onModalClose;

  window.addEventListener("popstate", () => {
    if (window.location.hash) checkNewsModalHash();
    else Modal.close();
  });
};

/**
 * Opens a news modal based on the url hash and the corresponding news item.
 */
const checkNewsModalHash = async () => {
  if (window.location.hash && newsWrapper) {
    const hash = window.location.hash;
    let trigger = document.querySelector(
      `[data-news-modal-trigger][href="${hash}"]`
    );
    let modalContent = trigger
      ? trigger.parentNode.querySelector(".news-article__modal-content")
      : null;

    // if there's no correcponding news item already in the grid,
    // generate a temporary one, so we can open a modal for it:
    if (!trigger) {
      const cleanSlug = hash.split("#!/")[1];
      const article = await fetchNewsFromSlug(cleanSlug);
      const tempItem = createArticleMarkup(article, "squares-purple");
      tempItem.id = "temporary-news-item";

      trigger = tempItem.querySelector("[data-news-modal-trigger]");
      modalContent = trigger.parentNode.querySelector(
        ".news-article__modal-content"
      );
    }

    onModalOpen(modalContent.innerHTML, trigger);
  }
};

export default renderNews;
