import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const cloudinaryEmbedUrl = 'https://player.cloudinary.com/embed/';

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const entry = node.data.target
      if (entry.__typename === 'Media') { 
        if (entry.asset.resourceType === 'image') {
          return `<img width="100%" height="auto" src="${entry.asset.url}" alt="">`;
        }
        if (entry.asset.resourceType === 'video') {
          const embedSrc = new URL(cloudinaryEmbedUrl)
          embedSrc.searchParams.set('cloud_name', 'ncom')
          embedSrc.searchParams.set('public_id', entry.asset.publicId)
          embedSrc.searchParams.set('cloudinary[cname]', 'assets.nintendo.com')
          embedSrc.searchParams.append('source[source_types]', 'mp4/h265')
          embedSrc.searchParams.append('source[source_types]', 'mp4')
          return `
            <iframe
              src="${embedSrc.toString()}"
              width="100%"
              style="aspect-ratio: 16/9"
              allow="fullscreen"
              frameBorder="0"
            />
          `;
        }
      } else if (entry.__typename === "YouTubeVideo" && entry.videoId) {
        // YouTube embed *must* be served from the `youtube-nocookie.com` domain
        return `
          <div class="youtube-embed">
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/${entry.videoId}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        `;
      }
    }
  }
};

function resolveEmbeddedLinks(node, links) {
  const entryId = node.data.target && node.data.target.sys && node.data.target.sys.id;
  if (node.nodeType === BLOCKS.EMBEDDED_ENTRY && entryId) {
    node.data.target = links.entries.block.find(({ id }) => entryId === id);
  } else if (node.content) {
    for (const child of node.content) {
      resolveEmbeddedLinks(child, links);
    }
  }
  return node;
}

function newsArticleRichText({ json, links }) {
  if (json.nodeType === 'document') {
    const document = resolveEmbeddedLinks(json, links)
    return documentToHtmlString(document, options)
  }
  return null
}

export default newsArticleRichText